import React from "react";
import PropTypes from "prop-types";
import Spacer from "../../../Spacer/Spacer";

const ResultItem = ({ resultData }) => {
  return (
    <div className="availableResults">
      <div className=" row resultContainer">
        {resultData.resultDate && (
          <p className="date col">{resultData.resultDate}</p>
        )}
        {resultData.copy && (
          <div
            className="resultInfo col" // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: resultData.copy,
            }}
          />
        )}
      </div>
      <Spacer mobileSize={20} size={20} />
      <hr size="3" />
      <Spacer mobileSize={20} size={20} />
    </div>
  );
};

ResultItem.propTypes = {
  resultData: PropTypes.shape({
    resultDate: PropTypes.string,
    copy: PropTypes.string,
  }).isRequired,
};
export default ResultItem;
