import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WhatWeLearnedSingle from "../components/WhatWeLearnedSingle/WhatWeLearnedSingle";

const WhatLearnedTemplate = ({ data: { whatLearned } }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <WhatWeLearnedSingle whatWeLearnedData={whatLearned} />
        <Footer />
      </Layout>
    </>
  );
};

export default WhatLearnedTemplate;

export const pageQuery = graphql`
  query WhatLearnedPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current news by id
    whatLearned: wpCrisisLearned(id: { eq: $id }) {
      id
      uri
      crisisLearned {
        headingSection {
          crisisName {
            ... on WpCrisis {
              id
              uri
              crisis {
                headingSection {
                  heading
                }
              }
            }
          }
          heading
          backButton {
            buttonLink
            text
          }
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: WEBP)
              }
            }
            altText
          }
        }
        crisisStatus
        crisisStatusHoverText
        topContent
        resultsSection {
          copy
          resultDate
        }
      }
      asignPromoSlots {
        heading
        copy
        choosePromoSlots {
          ... on WpPromoSlot {
            id
            promoSlotsFields {
              promoSlotsGroup {
                chooseBlogPost {
                  ... on WpPost {
                    id
                    excerpt
                    content
                    title
                    date(formatString: "MMMM DD, YYYY")
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]

                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                    uri
                    categories {
                      nodes {
                        name
                        uri
                        id
                      }
                    }
                    author {
                      node {
                        firstName
                        lastName
                        nicename
                        nickname
                        uri
                      }
                    }
                  }
                }
                choosePage {
                  ... on WpPage {
                    id
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                  }
                }
                promoSlotType
                content {
                  copy
                  link {
                    target
                    title
                    url
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
