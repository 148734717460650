import React from "react";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import IsrLabel from "../IsrLabel/IsrLabel";
import Jumbotron from "../Jumbotron/Jumbotron";
import Spacer from "../Spacer/Spacer";
import ResultItem from "./components/ResultItem/ResultItem";
import "./WhatWeLearnedSingle.scss";
import PromoSlotsSection from "../PromoSlotsSection/PromoSlotsSection";

const WhatWeLearnedSingle = ({ whatWeLearnedData }) => {
  const getCrisisStatusLabel = () => {
    switch (whatWeLearnedData.crisisLearned.crisisStatus) {
      case "Preparatory":
        return (
          <IsrLabel variant="hoverable" color="blue" size="md">
            Preparatory
          </IsrLabel>
        );
      case "Declared":
        return (
          <IsrLabel variant="hoverable" color="pink" size="md">
            Declared
          </IsrLabel>
        );
      case "Proposal submissions now closed":
        return (
          <IsrLabel variant="hoverable" color="grey" size="md">
            Proposal submissions now closed
          </IsrLabel>
        );

      default:
        return null;
    }
  };
  return (
    <div className="what-we-learned-single">
      <Jumbotron
        media={whatWeLearnedData.crisisLearned.headingSection.backgroundImage}
        title={
          whatWeLearnedData.crisisLearned.headingSection.crisisName.crisis
            .headingSection.heading
        }
        subtitle={whatWeLearnedData.crisisLearned.headingSection.heading}
        goBack={{
          url: whatWeLearnedData.crisisLearned.headingSection.crisisName.uri,
          title:
            whatWeLearnedData.crisisLearned.headingSection.crisisName.crisis
              .headingSection.heading,
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <Spacer mobileSize={50} size={100} />
            <div className="crisis-status">{getCrisisStatusLabel()}</div>
            <Spacer mobileSize={30} size={30} />
            {whatWeLearnedData.crisisLearned.topContent && (
              <>
                <div
                  className="content-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: whatWeLearnedData.crisisLearned.topContent,
                  }}
                />
                <Spacer mobileSize={25} size={50} />
              </>
            )}
            <div className="resultsContainer">
              {whatWeLearnedData.crisisLearned.resultsSection && (
                <p className="results">
                  Results (
                  {whatWeLearnedData.crisisLearned.resultsSection.length})
                </p>
              )}
              <Spacer mobileSize={15} size={30} />
              {whatWeLearnedData.crisisLearned.resultsSection &&
                whatWeLearnedData.crisisLearned.resultsSection.map((result) => (
                  <ResultItem
                    resultData={result}
                    key={uniqueId("result_item_")}
                  />
                ))}
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
      {whatWeLearnedData.asignPromoSlots && (
        <PromoSlotsSection data={whatWeLearnedData.asignPromoSlots} />
      )}
    </div>
  );
};

WhatWeLearnedSingle.propTypes = {
  whatWeLearnedData: PropTypes.shape({
    crisisLearned: PropTypes.shape({
      topContent: PropTypes.string,
      crisisStatus: PropTypes.string,
      headingSection: PropTypes.shape({
        heading: PropTypes.string,
        backgroundImage: PropTypes.object,
        crisisName: PropTypes.shape({
          uri: PropTypes.string,
          crisis: PropTypes.shape({
            headingSection: PropTypes.shape({ heading: PropTypes.string }),
          }),
        }),
      }),
      resultsSection: PropTypes.array,
    }),
    asignPromoSlots: PropTypes.object,
  }).isRequired,
};

export default WhatWeLearnedSingle;
